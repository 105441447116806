import kyInstance from "ky";

export const ky = kyInstance.create({
  prefixUrl: `${import.meta.env.VITE_FREETOBOOK_ORIGIN}/booking-pages/elements`,
  retry: 1,
  timeout: 30000,
});

export const userEventKy = kyInstance.create({
  prefixUrl: import.meta.env.VITE_USER_EVENT_ORIGIN,
  retry: 0,
  timeout: 10000,
});
