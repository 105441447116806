import { v7 as uuid } from "uuid";

import {
  getLocalStorageUserEventUser,
  setLocalStorageUserEventUser,
} from "@/elements/local-storage/local-storage.utilities";
import { type UserEventUser } from "@/elements/user-event/user/user-event-user";

export const createUserEventUser = (): UserEventUser => {
  const existingUser = getLocalStorageUserEventUser();

  if (existingUser) {
    return existingUser;
  }

  const user = {
    id: uuid(),
    createdAt: Date.now(),
  };

  setLocalStorageUserEventUser(user);

  return user;
};
