import { userEventKy } from "@/elements/ky/ky";
import type { UserEvent, UserEventDto } from "@/elements/user-event/user-event";

export const sendCreateUserEventRequest = async (
  userEvent: UserEvent,
): Promise<void> => {
  await userEventKy.post("events/booking-pages", {
    json: {
      user_event_identifier: {
        unique_user_id: userEvent.user.id,
        created_at_timestamp: userEvent.user.createdAt,
      },
      action: userEvent.action,
      action_metadata: {
        widget_id: userEvent.widgetId,
        property_id: userEvent.propertyId,
        host_url: userEvent.hostUrl,
      },
      user_agent: userEvent.userAgent,
      screen_size: {
        width: userEvent.screenSize.width,
        height: userEvent.screenSize.height,
      },
      datetime: userEvent.datetime,
      timezone: userEvent.timezone,
    } satisfies UserEventDto,
  });
};
