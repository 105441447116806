import type { UserEventUser } from "@/elements/user-event/user/user-event-user";

export enum UserEventAction {
  BookingButtonImpression = "elements_booking_button_impression",
  BookingButtonClick = "elements_booking_button_click",
}

export interface UserEvent {
  user: UserEventUser;
  widgetId: string;
  propertyId: string;
  action: UserEventAction;
  hostUrl: string;
  userAgent: string;
  screenSize: {
    width: number;
    height: number;
  };
  datetime: number;
  timezone: string;
}

export interface UserEventDto {
  user_event_identifier: {
    unique_user_id: string;
    created_at_timestamp: number;
  };
  action: UserEventAction;
  action_metadata: {
    widget_id: string;
    property_id: string;
    host_url: string;
  };
  user_agent: string;
  screen_size: {
    width: number;
    height: number;
  };
  datetime: number;
  timezone: string;
}
