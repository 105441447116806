
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's982dae569f5a22f1': `Système de reservation`,
's9ec2699513c0f9c3': `fermer`,
'sca477653ce0b6450': `Réservez maintenant`,
    };
  